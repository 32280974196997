body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to right, #ff7e5f, #feb47b); /* Your gradient */
  /* Ensures the background extends into the safe area */
  background-attachment: fixed;
}

.App {
  text-align: center;
  height: 100vh;
  margin: 0;
  padding-top: env(safe-area-inset-top);
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to right,
    #ff7e5f,
    #feb47b
  ); /* gradient background */
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}

.App-header {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 20px;
}

.Random-string {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.fade {
  opacity: 0;
}

.zero-style {
  font-family: "Montserrat", sans-serif; /* choose a font that differentiates 0 and O well */
  text-decoration: line-through; /* or another style like a dot in the center */
}

.App-logo {
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.App-logo .fas {
  margin-right: 10px; /* Adjust the space between the icon and the text */
  /* Other styles for the icon... */
}
